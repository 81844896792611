import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export default function SignIn() {
    const naviagte = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const formValue = Object.fromEntries(formData);
    
        localStorage.setItem("username", formValue?.username as string);

        naviagte("/");
    }

    return (
        <div className="bg-gray-100 flex items-center justify-center h-screen">
            <div className="bg-white p-8 rounded-lg shadow-md md:w-[400px] sm:w-80">
                <h2 className="text-xl text-[#7785cf] mb-4">Please login to continue!</h2>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div className="mt-4 mb-4">
                        <label htmlFor="username" className="block text-gray-700 font-medium mb-2">Username:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-[#7785cf] focus:border-[#7785cf] block w-full p-2.5"
                            placeholder="Enter your username"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-gray-700 font-medium mb-2">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-[#7785cf] focus:border-[#7785cf] block w-full p-2.5"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="flex">
                        <button type="submit" className="bg-[#7785cf] text-white px-4 py-2 rounded-md hover:bg-[#7785cf] focus:outline-none focus:bg-[#7785cf]">Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
}