export default function DoctorInCharge() {
  return (
    <div className={`rounded-md grid shadow gap-2 text-white p-4 bg-white`}>
      <p className="text-xs text-gray-400 font-[500] uppercase">
        Doctor In Charge
      </p>
      <div className="flex gap-2 items-center">
        <img
          className="w-11 h-11 rounded-full"
          src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
          alt=""
        />
        <div className="flex flex-col text-sm">
          <p className="text-gray-900 font-semibold">Subhash Ghimire</p>
          <p className="text-gray-400">MD, Orthopedic</p>
        </div>
      </div>
    </div>
  );
}
