import React, { useState } from "react";
import Tab from "./Tab";
import TabContent from "./TabContent";

interface TabItem {
  id: string;
  label: string;
}

export const tabItems: TabItem[] = [
  { id: "1", label: "X-Ray" },
  { id: "2", label: "CT Scan" },
  { id: "3", label: "MRI Scan" },
];

function TabbedNavigation() {
  const [activeTab, setActiveTab] = useState<string>(tabItems[0].id);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <div className="">
        <div className="mb-6">
          {tabItems.map((tab) => (
            <TabContent
              key={tab.id}
              id={tab.id}
              active={activeTab === tab.id}
              activeTab={activeTab}
            />
          ))}
        </div>
        <ul
          className="my-2 flex gap-2 list-none flex-row flex-wrap border-b-0 pl-0"
          role="tablist"
        >
          {tabItems.map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              label={tab.label}
              active={activeTab === tab.id}
              onClick={handleTabClick}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TabbedNavigation;
