interface TabProps {
  id: string;
  label: string;
  active: boolean;
  onClick: (id: string) => void;
}

function Tab({ id, label, active, onClick }: TabProps) {
  return (
    <li className="flex">
      <p
        className={`flex cursor-pointer items-center justify-center py-2 rounded-md w-20 text-sm font-semibold text-gray-700 ${
          active ? "bg-[#212121] text-white" : "border-transparent"
        }`}
        onClick={() => onClick(id)}
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {label}
      </p>
    </li>
  );
}

export default Tab;
