import Eye from "../images/eye.svg";
import { Link } from 'react-router-dom';
// @ts-ignore
import REPORT_FILE from "../file/report.pdf"

const TESTS = [
  "Nucleic acid amplification tests",
  "Serologic tests",
  "PRNT",
  "Cross-reactive flaviviruses",
  "IgG antibody testing",
  "Availability of Dengue Testing",
];

export default function Tests() {
  return (
    <ul className="-ml-4 pl-4">
      {TESTS.map((data, index) => (
        <li key={index} className="list-disc py-2 cursor-pointer">
          <div className="flex justify-between items-center">
            <span>{data}</span>
            <Link to={REPORT_FILE} rel="noreferrer" target="_blank" className="flex gap-1 items-center justify-end ">
              <p className="text-xs">View</p>
              <img src={Eye} alt="view-report" width={15} height={15} />
            </Link>
          </div>
        </li>
      ))}{" "}
    </ul>
  );
}
