const BP = [
  { lower: 70, upper: 110, remarks: "Normal" },
  {
    lower: 80,
    upper: 120,
    remarks: "Elevated",
    background: "bg-[#f751287a]",
    color: "text-white",
  },
  {
    lower: 120,
    upper: 180,
    remarks: "Normal",
  },
  {
    lower: 90,
    upper: 130,
    remarks: "Stage 1 Hypertension",
    background: "bg-[#ef4242a3]",
    color: "text-white",
  },
  {
    lower: 80,
    upper: 130,
    remarks: "Stage 2 Hypertension",
    background: "bg-[#ef4242c7]",
    color: "text-white",
  },
  { lower: 70, upper: 110, remarks: "Normal" },
  { lower: 70, upper: 115, remarks: "Normal" },
];

export const PAGE_HEIGHT = "md:h-[90vh] overflow-auto";

export default function ProfileSection() {
  return (
    <div
      className={`flex flex-col gap-4 text-left pt-4 pb:4 md:pb-20 px-5 ${PAGE_HEIGHT}`}
    >
      <div className="flex items-center justify-center w-full">
        <div className="bg-white rounded-md shadow w-full text-center">
          <img
            src="https://tecdn.b-cdn.net/img/new/avatars/2.webp"
            className="w-32 rounded-full my-4 mx-auto"
            alt=""
          />
          <div className="border-t p-2 bg-[#7785cf] text-white rounded-b-md">
            <p className="font-bold text-lg">Ram Bahadur</p>
            <p>Madhumara, Birgunj - 08, Parsa, Nepal</p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md shadow text-sm space-y-1 text-gray-800">
        <p className="text-base w-full rounded-t-md h-12 mb-3 border-b flex items-center font-semibold pl-4">
          General Information
        </p>
        <div className="px-4 pb-4 flex flex-col gap-2">
          <div className="flex flex-col gap-2 text-md">
            <p>
              <span className="font-semibold">Blood Group:</span>{" "}
              <span className="font-bold">A+</span>
            </p>
            <p>
              <span className="font-semibold">Height:</span>{" "}
              <span className="font-bold">6'0"</span>
            </p>
            <p>
              <span className="font-semibold">Weight:</span>{" "}
              <span className="font-bold">82Kg</span>
            </p>
          </div>
          <p>
            <span className="font-semibold">Allergies:</span>{" "}
            <span className="font-bold">
              Venom (Stinging Insect), Fish (food)
            </span>
          </p>
          <p>
            <span className="font-semibold">Ongoing treatment: </span>{" "}
            <span className="font-bold">None</span>
          </p>
        </div>
      </div>
      <div className="bg-white rounded-md shadow text-gray-700 w-full pb-1">
        <p className="text-base h-12 rounded-t-md border-b flex items-center font-semibold pl-4">
          BP Report (last 7 follow ups)
        </p>
        <table className="text-sm w-full rounded-md">
          <thead className="px-4 bg-gray-50 w-full">
            <tr>
              <th scope="col" className="py-2 px-4">
                Blood Pressure
              </th>
              <th scope="col" className="p-2">
                Remarks
              </th>
            </tr>
          </thead>
          <tbody>
            {BP.map((data, index) => (
              <tr
                className={`${data?.background ?? "bg-white"} ${
                  data?.color ?? "text-black"
                } border-b last:border-none`}
                key={index}
              >
                <th scope="row" className="py-2 px-4 font-medium">
                  {data.lower}/{data.upper}
                </th>
                <td className="p-2">{data.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white rounded-md shadow text-left">
        <p className="text-base w-full rounded-t-md h-12 border-b flex items-center font-semibold pl-4">
          Previous Diagnosis
        </p>
        <p className="p-4 text-md">
          Patient has blood pressure and has blood sugar level 200 mg/dL (11.1
          mmol/L)
        </p>
      </div>
    </div>
  );
}
