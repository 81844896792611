import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import "react-vertical-timeline-component/style.min.css";
import "yet-another-react-lightbox/styles.css";
import Detail from "./pages/detail";
import Home from "./pages";
import NewRecordLayout from "./pages/NewRecord";
import SignIn from "./pages/SignIn";
import PrivateRoutes from "./components/PrivateRoutes";
import Settings from "./pages/Settings";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/details" element={<Detail />} />
            <Route path="/new-record" element={<NewRecordLayout />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route path="/login" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
