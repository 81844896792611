import Navbar from "../components/Navbar";
import ProfileSection from "../components/ProfileSection";
import Summarize from "../components/SummarizeSection";

export default function Home() {
  return (
    <>
      <Navbar />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <ProfileSection />
        <Summarize />
      </div>
    </>
  );
}
