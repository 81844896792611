import React from "react";
import LightBox from "./LightBox";
import xray from "../images/xray.jpg";

interface TabContentProps {
  id: string;
  active: boolean;
  activeTab: string;
}

const tabImages: { [key: string]: { src: string; title: string }[] } = {
  "1": [
    {
      src: xray,
      title: "X-Ray",
    },
    {
      src: "https://c4.wallpaperflare.com/wallpaper/888/676/844/medicine-separated-shoulder-collar-bone-x-rays-wallpaper-preview.jpg",
      title: "X-Ray",
    },
    {
      src: xray,
      title: "X-Ray",
    },
  ],
  "2": [
    {
      src: "https://www.researchgate.net/profile/Luciano-Dagostino/publication/8998560/figure/fig2/AS:650939799203842@1532207550761/A-B-Delayed-phase-CT-scan-shows-diffuse-low-attenuation-area-with-indefinite-geographic.png",
      title: "X-Ray",
    },
    {
      src: "https://c1.wallpaperflare.com/preview/632/692/2/head-magnetic-resonance-imaging-mrt-x-ray.jpg",
      title: "X-Ray",
    },
  ],
  "3": [
    {
      src: "https://c1.wallpaperflare.com/preview/813/716/893/ankle-fracture-foot-medical.jpg",
      title: "X-Ray",
    },
    {
      src: "https://c1.wallpaperflare.com/preview/500/946/383/knee-old-care-injury.jpg",
      title: "X-Ray",
    },
    {
      src: "https://c1.wallpaperflare.com/preview/777/41/202/fracture-bone-xray-skeleton-diagnosis.jpg",
      title: "X-Ray",
    },
    {
      src: "https://c1.wallpaperflare.com/preview/259/447/793/x-ray-health-arm-doctors.jpg",
      title: "X-Ray",
    },
  ],
};

function TabContent({ id, active, activeTab }: TabContentProps) {
  return (
    <div
      className={`${
        active ? "opacity-100 block" : "opacity-0 hidden"
      } transition-opacity duration-150 ease-linear rounded-md `}
      id={id}
      aria-labelledby={id}
    >
      <LightBox images={tabImages[activeTab]} />
    </div>
  );
}

export default TabContent;
