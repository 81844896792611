import { Dispatch, SetStateAction, useState } from "react";

interface InputProps {
  label: string;
  placeholder: string;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
  slideNumber: number;
}

export default function Input({
  label,
  placeholder,
  setActiveSlideIndex,
  slideNumber,
}: InputProps) {
  const [value, setValue] = useState("");
  const handleSubmit = () => {
    // Read the form data
    console.log({ value });
    console.log({ slideNumber });
    setActiveSlideIndex(slideNumber + 1);
  };

  return (
    <div className="card shadow-sm h-full mt-8">
      <div className="h-20 bg-[#7785cf] rounded-t-md flex items-center px-8 justify-between">
        <p className="text-2xl text-white">{label}</p>
        <div className="flex gap-4">
          <button
            type="button"
            className="px-4 py-1 rounded text-white"
            onClick={() => {
              setValue("");
            }}
          >
            Clear
          </button>
          <button
            onClick={handleSubmit}
            type="button"
            className="px-4 py-1 bg-white text-[#7785cf] rounded"
          >
            Save
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full sm:w-[500px] md:w-[700px] h-[600px] p-8 bg-white justify-between rounded-md"
      >
        <div className="flex flex-col gap-4 h-full">
          <textarea
            id={placeholder}
            value={value}
            placeholder={placeholder}
            className="outline-none px-3 py-2 rounded-md h-full bg-gray/5 w-full border-text placeholder-gray/80"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
}
