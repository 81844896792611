import { Link } from "react-router-dom";
import LOGO from "../images/logo.png";

export default function Navbar() {
  return (
    <div className="mb-16">
      <nav className="fixed top-0 flex-no-wrap flex w-full items-center justify-between bg-[#efeff5] md:bg-transparent py-2 lg:flex-wrap lg:justify-start lg:py-4">
        <div className="flex w-full flex-wrap items-center justify-end sm:justify-between px-3">
          {/* <button
            className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button> */}

          <div
            className="flex-grow"
            id="navbarSupportedContent1"
            data-te-collapse-item
          >
            <Link
              className="ml-2 mr-5 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-0 lg:mt-0"
              to="/"
            >
              <img
                src={LOGO}
                alt="TE Logo"
                loading="lazy"
                className="h-14"
              />
            </Link>
          </div>

          <div className="relative flex items-center">
            <div
              className="relative"
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              <Link
                className="hidden-arrow mr-4 flex items-center text-gray-400 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none"
                to="/settings"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <span className="[&>svg]:w-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-cog">
                    <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"/>
                    <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                    <path d="M12 2v2"/>
                    <path d="M12 22v-2"/>
                    <path d="m17 20.66-1-1.73"/>
                    <path d="M11 10.27 7 3.34"/>
                    <path d="m20.66 17-1.73-1"/>
                    <path d="m3.34 7 1.73 1"/>
                    <path d="M14 12h8"/>
                    <path d="M2 12h2"/>
                    <path d="m20.66 7-1.73 1"/>
                    <path d="m3.34 17 1.73-1"/>
                    <path d="m17 3.34-1 1.73"/>
                    <path d="m11 13.73-4 6.93"/>
                  </svg>
                </span>
              </Link>
            </div>
            <div
              className="relative"
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              <Link
                className="hidden-arrow mr-4 flex items-center text-gray-400 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none"
                to="#"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <span className="[&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>

                <span className="absolute -mt-4 ml-2.5 rounded-full bg-red-600 px-[.5em] py-[0.15em] text-[0.6rem] leading-none text-white">
                  1
                </span>
              </Link>
            </div>

            <div
              className="relative"
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              <Link
                className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
                to="#"
                id="dropdownMenuButton2"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <img
                  src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
                  className="rounded-full"
                  style={{
                    height: "25px",
                    width: "25px",
                  }}
                  alt=""
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
