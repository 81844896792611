export default function DetailCard({ label, children, className }: any) {
  return (
    <div
      className={`rounded-md grid shadow gap-2 text-white px-4 ${className}`}
    >
      <p className="text-base w-full rounded-t-md h-10 flex items-center font-semibold">
        {label}
      </p>
      <div className="text-sm px-4 pb-4">{children}</div>
    </div>
  );
}
