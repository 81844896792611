import { useState } from "react";
import Input from "../components/Input";
import Carousel from "react-simply-carousel";
import ProfileSection, { PAGE_HEIGHT } from "../components/ProfileSection";
import Navbar from "../components/Navbar";

const ITEMS = [
  { label: "Symptoms", placeholder: "What are the patient symptoms?" },
  { label: "Diagnosis", placeholder: "Patient diagnosis" },
  { label: "Tests", placeholder: "Tests for the patient" },
  { label: "Medication", placeholder: "Patient Medication" },
  { label: "Suggestions", placeholder: "Any suggestion for Do's and Dont's" },
];

function NewRecord() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div
      className={`col-span-2 w-full p-4 flex items-center justify-center gap-2 ${PAGE_HEIGHT}`}
    >
      <Carousel
        containerProps={{ style: { height: "100%", maxWidth: "100%" } }}
        visibleSlideProps={{ style: { maxWidth: "100%" } }}
        itemsToShow={1}
        itemsToScroll={1}
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        easing="linear"
        speed={400}
        preventScrollOnSwipe
        swipeTreshold={60}
      >
        {ITEMS.map((item, index) => (
          <Input
            key={index}
            {...item}
            slideNumber={index}
            setActiveSlideIndex={setActiveSlideIndex}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default function NewRecordLayout() {
  return (
    <>
      <Navbar />
      <div className="grid grid-cols-3 gap-2 overflow-auto">
        <ProfileSection />
        <NewRecord />
      </div>
    </>
  );
}
