const CARD_DATA = [
  {
    title: "MRI: right thigh, knee and shin",
    borderColor: "border-[#7785cf]",
    date: "01:30 PM - 02:20 PM",
    doctorName: "Nitesh Kumar Singh",
    degree: "MD, Orthopedic surgeon",
  },
  {
    title: "Consultation: surgery prepration",
    borderColor: "border-[#f39e8d]",
    date: "01:30 PM - 02:20 PM",
    doctorName: "Nitesh Kumar Singh",
    degree: "MD, Orthopedic surgeon",
  },
  {
    title: "MRI: surgery prepration",
    borderColor: "border-blue-500",
    date: "01:30 PM - 02:20 PM",
    doctorName: "Nitesh Kumar Singh",
    degree: "MD, Orthopedic surgeon",
  },
];

export default function TreatmentCard() {
  return (
    <div className="space-y-3">
      {CARD_DATA.map((data) => (
        <div
          className={`bg-white rounded-lg shadow py-3 w-full border-l-[3px] ${data.borderColor}`}
        >
          <div className="ml-4 flex flex-col">
            <span className="text-xs text-gray-500 font-semibold">
              {data.date}
            </span>
            <p className="text-base w-full text-gray-800 rounded-t-md h-10 mb-3 border-b flex items-center font-semibold">
              {data.title}
            </p>
          </div>

          <div className="flex items-center space-x-4 ml-4">
            <img
              className="w-10 h-10 rounded-full"
              src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
              alt=""
            />
            <div className="font-semibold">
              <div>{data.doctorName}</div>
              <div className="text-sm font-medium text-gray-500">
                {data.degree}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
