import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Inline from "yet-another-react-lightbox/plugins/inline";
import ZoomIcon from "../images/zoom.svg";
interface LightBoxProps {
  images: any;
}

export default function LightBox({ images }: LightBoxProps) {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);

  const toggleOpen = (state: boolean) => () => setOpen(state);

  return (
    <div className="">
      <div className="relative">
        <Lightbox
          index={index}
          slides={images}
          plugins={[Inline]}
          on={{
            view: updateIndex,
            click: toggleOpen(true),
          }}
          carousel={{
            padding: 0,
            spacing: 0,
            imageFit: "cover",
          }}
          inline={{
            style: {
              width: "100%",
              maxWidth: "900px",
              aspectRatio: "3 / 2",
              margin: "0 auto",
              borderRadius: "20px",
            },
          }}
        />
        <img
          alt=""
          src={ZoomIcon}
          className="absolute bottom-3 right-5 text-white z-10 hover:cursor-pointer"
          onClick={toggleOpen(true)}
        />
      </div>

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={images}
        plugins={[Fullscreen, Zoom]}
        on={{ view: updateIndex }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        animation={{ zoom: 500 }}
        zoom={{
          maxZoomPixelRatio: 1,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: false,
        }}
      />
    </div>
  );
}
