import Tests from "../components/Tests";
import DetailCard from "../components/DetailCard";
import TreatmentCard from "../components/TreatmentCard";
import TabbedNavigation from "../components/TabbedNavigation";
import DoctorInCharge from "../components/DoctorInCharge";
import AppointmentDetail from "../components/AppointmentDetail";
import Navbar from "../components/Navbar";

const gridStyle = `space-y-4 pt-5`;

export default function Detail() {
  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-3 px-6 gap-4 pb-4">
        <div className={gridStyle}>
          <DetailCard label="Symptoms" className="bg-white !text-gray-900">
            <ul className="list-disc">
              <li>high fever (40°C/104°F)</li>
              <li>severe headache</li>
              <li>pain behind the eyes</li>
              <li>swollen glands</li>
            </ul>
          </DetailCard>
          <DetailCard label="Diagnosis" className="bg-[#8692d1]">
            <div className="-ml-4 grid gap-1">
              <p>
                <span className="font-semibold">Ligament tear:</span> a tear of
                any of ligaments in the knee
              </p>
            </div>
          </DetailCard>{" "}
          <TreatmentCard />
        </div>
        <div className={`${gridStyle} space-y-12`}>
          <TabbedNavigation />
          <DetailCard
            label="Lab Tests and Reports"
            className="bg-white !text-gray-900"
          >
            <Tests />
          </DetailCard>{" "}
        </div>
        <div className={gridStyle}>
          <DetailCard label="Medications" className="bg-[#f39e8d]">
            <ul className="space-y-1 list-disc">
              <li>Ibuprofen</li>
              <li>Naproxen</li>
              <li>Acetaminophen</li>
              <li>Aspirin (regular strength)</li>
            </ul>
          </DetailCard>{" "}
          <DetailCard label="Suggestions" className="bg-white !text-gray-900">
            <div className="-ml-4 space-y-1">
              <p>
                Acetaminophen (paracetamol) is recommended for treatment of pain
                and fever.
              </p>
              <p>
                {" "}
                Aspirin, other salicylates, and nonsteroidal anti-inflammatory
                drugs (NSAIDs) should be avoided.
              </p>
            </div>
          </DetailCard>{" "}
          <div className="h-16 flex items-center bg-[#63a3b2] text-white pl-4 text-md font-[500] rounded-md">
            <p>Appointment Information</p>
          </div>
          <div className="flex">
            <p className="text-gray-500 text-xs">Mon, 18th Nov 2023</p>
          </div>
          <DoctorInCharge />
          <AppointmentDetail />
          {/* <div className="px-4 space-y-4 text-gray-900">
						<p className="text-md font-semibold">Additional information</p>
						<ul className="space-y-2">
							<li className="bg-white pl-4 py-2 rounded-xl shadow-sm">
								Note: How to prepare for surgery
							</li>
							<li className="bg-white pl-4 py-2 rounded-xl shadow-sm">
								PE during rehabilitation process
							</li>
							<li className="bg-white pl-4 py-2 rounded-xl shadow-sm">
								Note: How to prepare for surgery
							</li>
						</ul>
					</div> */}
        </div>
      </div>
    </div>
  );
}
