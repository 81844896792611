import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";

const CAN_VIEW_ALL = "all";
const CAN_VIEW_SELECTED = "selected";

export default function Settings() {
    const [canViewRecord, setCanViewRecord] = useState("");

    useEffect(() => {
        const canViewRecordFromStorage = localStorage.getItem("canViewRecord");
        setCanViewRecord(canViewRecordFromStorage!);
    }, [canViewRecord]);

    const setViewRecord = (value: string) => {
        setCanViewRecord(value);
        localStorage.setItem("canViewRecord", value);

        if (value === "") {
            localStorage.removeItem("canViewRecord");
        }
    }

    return (
        <>
            <Navbar />
            <div className="container mx-auto px-3 md:w-[600px] w-full">
                <div className="flex items-center justify-between p-2 mb-6">
                    <h1 className="text-xl">Information Access Control</h1>
                    <span className="text-[#7785cf]" onClick={() => setViewRecord("")}>Reset</span>
                </div>
                <div className="bg-white rounded-lg shadow-sm">
                    <div className="flex items-center justify-between px-6 py-2">
                        <span>All Medical Professionals</span>
                        <div>
                            <input
                                className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#7785cf] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#7785cf] checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-[#7785cf] checked:focus:bg-[#7785cf] checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-[#7785cf] dark:checked:after:bg-[#7785cf] dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1setAllMedicalProfessionalspx_0px_13px_#3b71ca]"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault02"
                                checked={canViewRecord === CAN_VIEW_ALL}
                                onChange={() => setViewRecord(CAN_VIEW_ALL)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="flex items-center justify-between px-6 py-2">
                        <span>Assigned Doctor Only</span>
                        <div>
                            <input
                                className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#7785cf] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#7785cf] checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-[#7785cf] checked:focus:bg-[#7785cf] checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-[#7785cf] dark:checked:after:bg-[#7785cf] dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault02"
                                checked={canViewRecord === CAN_VIEW_SELECTED}
                                onChange={() => setViewRecord(CAN_VIEW_SELECTED)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}