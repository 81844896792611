import { Navigate, Outlet } from "react-router-dom";
import Forbidden from "../pages/Forbidden";

export default function PrivateRoutes() {
    const username = localStorage.getItem("username");
    const canViewRecord = localStorage.getItem("canViewRecord");

    if (!username) {
        return <Navigate to={"/login"} />;
    }

    const canAccess: boolean = canViewRecord === "all"
        ? true
        : (canViewRecord === "selected" && username === "doctor1") ||
            username === "patient1";

    return (
        canAccess ? <Outlet /> : <Forbidden />
    )
}