import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Link, useNavigate } from "react-router-dom";
import { PAGE_HEIGHT } from "./ProfileSection";

const TREATMENT_HISTORY = [
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2023-05-03",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2022-04-23",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2020-08-03",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2020-05-03",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis hehe hehe",
    test: "test",
    medicine: "medicine",
    date: "2019-05-12",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2018-05-08",
  },
  {
    symptoms: "symptoms",
    diagnosis: "diagnosis",
    test: "test",
    medicine: "medicine",
    date: "2010-12-25",
  },
];

// const PRIMARY_COLOR = "bg-[#7785cf]";
const PRIMARY_COLOR = "#7785cf";
const TEXT_COLOR = "text-[#7785cf]";

export default function Summarize() {
  const navigate = useNavigate();
  return (
    <div className={`col-span-2 p-3 pt-0 ${PAGE_HEIGHT}`}>
      <div className="sticky top-0 z-10 h-24 align-baseline">
        <div className="h-4 bg-[#efeff5]" />
        <p
          className={`h-20 flex shadow-sm items-center justify-center bg-[#7785cf] text-white mb-2 p-2 text-xl font-semibold uppercase rounded-sm`}
        >
          Treatment History
        </p>
      </div>

      <VerticalTimeline lineColor="#7785cf">
        {TREATMENT_HISTORY.map((data, index) => (
          <VerticalTimelineElement
            key={index}
            // className="vertical-timeline-element--work"
            contentStyle={{ background: "white", color: "black" }}
            contentArrowStyle={{
              borderRight: `7px solid  white`,
              marginTop: "2px",
            }}
            iconStyle={{ background: `${PRIMARY_COLOR}`, color: "#fff" }}
            iconClassName="mt-6 !-ml-[10px] !h-4 !w-4"
            date={data.date}
            dateClassName="text-black"
          >
            <div className="h-[300px]">
              <div
                className={`flex items-center font-bold border-[#7785cf] justify-center rounded-t-md h-12 border-b ${TEXT_COLOR}`}
              >
                Dr. Subash Ghimire (Ortho)
              </div>
              <div className="px-4">
                <div className="grid gap-1">
                  <p className="font-bold">Symptoms</p>
                  <p className="!font-normal !mt-0 !text-sm line-clamp-3 w-full truncate whitespace-normal">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div className="grid gap-1">
                  <p className="font-bold">Diagnosis</p>
                  <p className="!font-normal !mt-0 !text-sm line-clamp-3 w-full truncate whitespace-normal">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
                <Link to="/details">
                  <p className="!text-sm !font-normal text-[#7785cf] hover:underline">
                    View full report...
                  </p>
                </Link>
              </div>
            </div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
      <button
        onClick={() => navigate("/new-record")}
        className="cursor-pointer h-20 w-20 text-center bg-[#7785cf] px-4 py-2 rounded-full text-white text-4xl absolute bottom-4 right-4"
      >
        +
      </button>
    </div>
  );
}
